import DOMPurify from 'dompurify';

function GoogleNewsResult({ items }) {
    if (items && items.length) {
        for (let i = 0; i < items.length; i++) {
            // only keep items with question and answers
            if (items[i].question === "" || items[i].answers === "") {
                items.splice(i, 1);
                i--;
                continue;
            }
            let sanitizedHtml = DOMPurify.sanitize(items[i].title);
            items[i].title = sanitizedHtml;

            sanitizedHtml = DOMPurify.sanitize(items[i].snippet);
            items[i].snippet = sanitizedHtml;
        }
    }

    return (
        <>
            {items.length > 0 && <h3 className="h4 mb-4">Weitere News und Tests</h3>}
            {items && items.map((item, index) => {
                return (
                    <div key={index} className="mb-4">
                        <h4 className="h6 mb-1"><strong>{item.title}</strong></h4>
                        <p>
                            {item.snippet} <br />
                            <a href={item.url} target='_blank' rel='noreferrer'>
                                {item.source}
                            </a>
                        </p>
                    </div>
                )
            })}
            {items.length > 0 && <p className='small'>Alle Angaben (News und Tests) ohne Gewähr.</p>}
        </>
    );
}

export default GoogleNewsResult;