import { useContext, useEffect, useState } from "react";
import MyNavBar from "../Components/MyNavBar";
import { ApiUrlContext } from "../GlobalContext/ApiUrlContext";
import { Rating } from "@smastrom/react-rating";
import MyLoader from "./MyLoader";
import MyImageCarousel from "./MyImageCarousel";

function TopAccessories({ jsonUrl, setPageTitle, updateBaseUrl }) {

    const [initialRender, setInitialRender] = useState(true);
    const [topSellerItems, setTopSellerItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = useContext(ApiUrlContext);

    setPageTitle('Top Zubehör');
    updateBaseUrl('/topaccessories');

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
        } else {
            //const urltemp = apiUrl + 'phones/topAccessoriesJson.json';
            const urltemp = apiUrl + jsonUrl;
            setIsLoading(true);
            fetch(urltemp, {
                method: 'GET'
            })
                .then(response => response.json())
                .then((result) => {
                    setTopSellerItems(result.result);
                    console.log('topsellers', result.result);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching dataFiles:', error);
                    setIsLoading(false);
                });
        }
    }, [initialRender, apiUrl, jsonUrl]);

    return (
        <>
            <MyNavBar />
            <MyLoader isLoading={isLoading} />
            <div className="container mt-5 mb-5">
                <div className="row ">
                    <div className="col-lg-9 bg-lightxx text-black">
                        <div className="sidebar-header pb-4">
                            <h1 className='h4 main-color'>
                                Top Zubehör
                            </h1>
                            <p className=''>
                                <strong>
                                    Das Top-Zubehör
                                </strong>
                            </p>
                            <p>Die verlinkten Titel und Bewertungen öffnen die jeweilige Amazon Produktseite.</p>
                            <p className="">
                                Die Tabelle mit allen Ergebissen findet sich <a href="/" className="main-color">hier</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container container-bestsellers min-height">
                <div className="row ">
                    <ul className="list-unstyled">
                        {topSellerItems && Object.keys(topSellerItems).map((key, idx) => (
                            <li key={idx} className="" >
                                <div className="pb-5 mb-5 border-bottom">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 slide-container">
                                            {topSellerItems[key].images[0] &&
                                                <>
                                                    {/*
                                                    <a href={topSellerItems[key].url} target="_blank" rel="noreferrer">
                                                        <img src={topSellerItems[key].images[0]}
                                                            alt={topSellerItems[key].title} />
                                                    </a>
                                                    */}
                                                    <MyImageCarousel images={topSellerItems[key].images} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-9 col-md-8">
                                            <div className="ml-5 mt-5 mt-md-0">
                                                <h3 className="h6 pb-3">
                                                    {topSellerItems[key].title}
                                                </h3>

                                                {(topSellerItems[key].description !== "") && <p>{topSellerItems[key].description}</p>}

                                                <strong>Features:</strong>
                                                <ul className="mt-2">
                                                    {Object.keys(topSellerItems[key].feature_bullets).map((key2, idx2) => (
                                                        <li key={idx2}>{topSellerItems[key].feature_bullets[key2]}</li>
                                                    ))}
                                                </ul>
                                                <div className="mt-3">
                                                    {topSellerItems[key].number_of_people_bought &&
                                                        <div className="mt-1">Verkäufe: <strong>{topSellerItems[key].number_of_people_bought}</strong></div>
                                                    }
                                                    {topSellerItems[key].price &&
                                                        <div className="mt-1">Preis: <strong>€{topSellerItems[key].price}</strong></div>
                                                    }
                                                </div>
                                                <div className="mt-1">
                                                    <div className="float-start pe-2">Bewertungen: &nbsp;
                                                        <a href={topSellerItems[key].url + '#customerReviews'} className='underline' target="_blank" rel="noreferrer" title={"Externer Link zu den Bewertungen für " + topSellerItems[key].title}>
                                                            <strong>{topSellerItems[key].total_reviews}</strong>
                                                        </a>
                                                    </div>
                                                    <div className="float pt-0">
                                                        <Rating style={{ maxWidth: 90 }} value={topSellerItems[key].stars} readOnly={true} />
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    Mehr Details, aktuelle Preise und Reviews auf <strong><a href={topSellerItems[key].url} target="_blank" rel="noreferrer">amazon.de</a> (*)</strong>
                                                </div>
                                                {/*
                                                {topSellerItems[key].highlighted_keywords_filtered.length > 0 &&
                                                    <div key={idx} className="mt-5 feature-pills">
                                                        {Object.keys(topSellerItems[key].highlighted_keywords_filtered).map((key2, idx2) => (
                                                            <span key={idx2} className="badge rounded-pill bg-secondary">
                                                                {topSellerItems[key].highlighted_keywords_filtered[key2]}
                                                            </span>
                                                        ))}
                                                    </div>
                                                }
                                                */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );

};

export default TopAccessories;