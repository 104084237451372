import DOMPurify from 'dompurify';

function GooglePeopleAskedFor({ items }) {
    if (items && items.length) {
        for (let i = 0; i < items.length; i++) {
            // only keep items with question and answers
            if (items[i].question === "" || items[i].answers === "") {
                items.splice(i, 1);
                i--;
                continue;
            }
            let sanitizedHtml = DOMPurify.sanitize(items[i].question);
            items[i].question = sanitizedHtml;

            sanitizedHtml = DOMPurify.sanitize(items[i].answers);
            items[i].answers = sanitizedHtml;
        }
    }

    return (
        <>
            {items.length > 0 && <h3 className="h4 mb-4">Fragen und Antworten</h3>}
            {items && items.map((item, index) => {
                return (
                    <div key={index} className="mb-4">
                        <h4 className="h6 mb-1"><strong>{item.question}</strong></h4>
                        <p>
                            {item.answers} <br />
                            <a href={item.link} target='_blank' rel='noreferrer'>
                                {item.displayed_link}
                            </a>
                        </p>
                    </div>
                )
            })}
            {items.length > 0 && <p className='small'>Alle Angaben (Fragen und Antworten) ohne Gewähr.</p>}
        </>
    );
}

export default GooglePeopleAskedFor;