
import DOMPurify from 'dompurify';


function CustomerReviews({ completeItem, customerReviews }) {

    if (customerReviews && customerReviews.length) {
        for (let i = 0; i < customerReviews.length; i++) {
            const sanitizedHtml = DOMPurify.sanitize(customerReviews[i].review_snippet);
            customerReviews[i].review_snippet = sanitizedHtml;
        }
    }

    return (
        <>
            <h3 className="h4 mb-4">Rezensionen (Auszug)</h3>
            <div className="mb-4">
                <a href={completeItem.url + '#customerReviews'} className='underline mb' target="_blank" rel="noreferrer"
                    title={"Externer Link zu den Bewertungen für " + completeItem.title}>
                    <strong>Alle Rezensionen</strong>
                </a>
            </div>
            {customerReviews && customerReviews.map((item, index) => {
                return (
                    <div key={index} className="mb-4">
                        <h4 className="h6 mb-1"><strong>{item.review_title}</strong></h4>
                        <div className="mt-1 mb-1 small">Von <strong>{item.customer_name}</strong></div>
                        <div dangerouslySetInnerHTML={{ __html: item.review_snippet }} />
                    </div>
                )
            })}
        </>
    );
}

export default CustomerReviews;